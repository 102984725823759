<template>
  <div>
    <v-card class="blue_line px-5 pb-3 pt-3 mb-3" flat v-if="commercialAgreements">
      <div v-for="(commercialAgreement, key) in commercialAgreements" :key="key">
        <div class="d-flex ml-0" style="position: relative;">
          <v-icon class="mb-6" color="#2222BF">mdi-file-pdf-outline</v-icon>
          <div class="ml-2">
            <a class="mb-0 mt-1 text-decoration-underline" style="color: #2222BF; font-size: 13px" :href="`${WEB_HOST}/es/remuneraciones/avanzados/editar-acuerdo-comercial/${commercialAgreement.id}`" target="_blank">Acuerdo comercial N° {{commercialAgreement.id}}</a>
            <p class="mb-0" style="color: #757575; font-size: 13px">Vigente desde el {{commercialAgreement.since | moment('DD/MM/YYYY')}}</p>
          </div>
          <a :href="`${WEB_HOST}/es/remuneraciones/avanzados/editar-acuerdo-comercial/${commercialAgreement.id}`"
          style="position: absolute; right: 0; top: 50%; transform: translateY(-50%); color: #2222BF; max-width: 20%; text-align: right; font-size: 11px;">
            <v-icon color="#2222BF">mdi-eye-outline</v-icon>
          </a>
        </div>
        <v-divider class="mb-2" v-if="(key + 1) < commercialAgreements.length"></v-divider>
      </div>
    </v-card>
    <v-card flat class="py-3 px-6 blue_line">
      <div class="mb-3" style="position: relative; font-size: 13px;">
        <p class="mb-0" style="width: 75%; font-size: 13px; color: #757575">Deuda total</p>
        <a :href="`${WEB_HOST}/es/facturacion/morosos/${company.id}`"
        style="position: absolute; right: 0; top: 50%; transform: translateY(-50%); color: #2222BF; max-width: 50%; text-align: right; font-size: 13px;">
          Ver detalle de factura
        </a>
      </div>
      <p class="font-weight-medium mb-0" style="font-size: 22px;">$ {{Number.isInteger(debt) ? formattedNumber(debt) : '-'}}</p>
    </v-card>
  </div>
</template>

<script>
let WEB_HOST = window.location.host.includes('localhost') ? 'http://localhost:9000' : 'https://' + window.location.host.substring(8)

import { mapGetters, mapState } from 'vuex'

export default {
  props: ['commercialAgreements', 'debt'],
  data: () => ({
    WEB_HOST
  }),
  computed: {
    ...mapGetters(['formattedNumber']),
    ...mapState(['company'])
  }
}
</script>

<style lang="scss">
.blue_line{
  border-left:6px solid #2222BF!important;
}
</style>
