<template>
  <div v-if="info">
    <v-card flat class="">
      <div class="px-5 py-4 white--text" style="background: #2222BF; position: relative;">
        <v-avatar class="white" :style="avatar_style" size="100" rounded>
          <img :src="info.logo" :alt="info.log" v-if="info.logo" style="position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); min-height: 100%; height: 100%; width: auto;">
          <span style="font-size: 26px; color: #181818; font-weigth: bold;" v-else>{{info.razonSocial[0]}}</span>
        </v-avatar>
      </div>
      <div class="px-6 pb-3 pt-8">
        <div class="py-2">
        <p class="mb-0 mt-2" style="font-size: 22px; font-weight: bold;">{{info.razonSocial ? info.razonSocial : '-'}}</p>
        <p style="font-size: 14px;">{{info.rut ? info.rut : '-'}} · {{info.direccion ? info.direccion : '-'}}</p>
      </div>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Razón Social</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.razonSocial ? info.razonSocial : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Giro</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.giro ? info.giro  : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Nombre Representante Legal</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.representanteLegal ? info.representanteLegal : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">RUT Representante Legal</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.rutRepresentanteLegal ? info.rutRepresentanteLegal : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Caja de compensación</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.cajaCompensacion ? info.cajaCompensacion : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Mutual</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.mutual ? info.mutual : '-' }}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Monto cotización Mutual</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.montoMutual ? info.montoMutual : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Banco Pago Nómina</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.bancoPagoNomina ? info.bancoPagoNomina : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Cuenta Corriente Pago Nómina</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.cuentaCorrientePagoNomina ? info.cuentaCorrientePagoNomina : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Forma de Pago de Gratificación</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.formaDePagoDeGratificacion ? info.formaDePagoDeGratificacion : '-'}}</span>
        </div>
        <v-divider class="my-1"/>
        <div class="py-2" style="position: relative; font-size: 13px; display: flex; align-items: center;">
          <p class="my-0" style="width: 50%; color: #757575; height: max-content;">Forma de Pago de Asignación de Movilización y Colación</p>
          <span style="position: relative; right: 0; color: #181818; width: 50%; text-align: right;">{{info.formaDePagoDeMovilizacionYColacion ? info.formaDePagoDeMovilizacionYColacion : '-'}}</span>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

export default {
  props: ['info'],
  data() {
    return {
      avatar_style: 'margin-bottom: -50px; z-index: 10; box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); position: relative;'
    }
  },
  computed: {
    ...mapState(['company']),
    ...mapGetters(['formattedNumber'])
  }
}
</script>

<style lang="scss">
</style>
