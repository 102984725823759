<template>
  <v-main>
    <div v-if="summary">
      <v-row>
        <v-col cols="12" lg="4">
          <summary-details :info="summary.business_name"/>
        </v-col>
        <v-col cols="12" lg="4">
          <summary-commercial :commercialAgreements="summary.commercial_agreements" :debt="summary.total_debt"/>
          <summary-services :services="summary.services"/>
        </v-col>
        <v-col cols="12" lg="4">
          <summary-client-log :logs="summary.bitacora_entries"/>
        </v-col>
      </v-row>
    </div>
  </v-main>
</template>

<script>
import { mapState, mapActions } from 'vuex'

import SummaryDetails from '../components/Summary/SummaryDetails'
import SummaryCommercial from '../components/Summary/SummaryCommercial'
import SummaryServices from '../components/Summary/SummaryServices'
import SummaryClientLog from '../components/Summary/SummaryClientLog'

export default {
  components: {
    SummaryDetails,
    SummaryCommercial,
    SummaryServices,
    SummaryClientLog
  },
  data: () => ({}),
  methods: {
    ...mapActions(['getSummary'])
  },
  computed: {
    ...mapState(['summary'])
  },
  created() {
    this.getSummary()
  }
}
</script>

<style>

</style>
