<template>
  <div v-if="services">
    <v-card flat class="px-5 py-6 my-5">
      <p class="mb-6" style="font-size: 16px; font-weight: bold;">Servicios contratados</p>
      <v-row v-for="(service,i) in services" :key="i">
        <v-col cols="12" lg="2" sm="1" class="md-2 d-inline-block">
          <v-avatar style="background-color: #F4F4FF;" size="52">
          <v-img
            style="border-radius: none;"
            contain
            height="25px"
            :src="serviceIcon(service)"
          ></v-img>
        </v-avatar>
        </v-col>
        <v-col cols="12" lg="10" sm="11">
          <div
            style="height: 100%"
            class="mb-0 d-flex"
          >
            <p
              class="mb-0 font-weight-medium"
              style="color: #2222bf; font-size: 14px; line-height: 1.2, height: 100%; display: flex; align-items: center;"
            >
              {{serviceName(service)}}
            </p>
          </div>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  props: ['services'],
  data: () => ({
    publicPath: location.origin
  }),
  methods: {
    serviceIcon(name){
      let icon = ''
      if(name=="Turnos"){ icon = 'asistencia_turnos.svg'}
      else if(name=="Comunicaciones"){ icon = 'comunicaciones.svg'}
      else if(name=="Gestión de Personas"){ icon = 'gestion_personas.svg'}
      else if(name=="Firma Digital"){icon = 'firma_digital.svg'}
      else if(name=="Remuneraciones"){icon = 'remuneraciones.svg'}
      else if(name=="DO"){icon = 'desarrollo_organizacional.svg'}
      else if(name=="Mesa de Ayuda"){icon = 'centro_solicitudes.svg'}
      else {icon = 'talana_logo.svg'}
      return `${this.publicPath}/img/services_icons/${icon}`
    },
    serviceName(name){
      if(name=="Turnos"){return "Control de Asistencia y Turnos"}
      if(name=="DO"){return "Desarrollo Organizacional"}
      return name
    }
  }
}
</script>

<style lang="scss">
.v-avatar img, .v-avatar svg, .v-avatar .v-icon, .v-avatar .v-image, .v-avatar .v-responsive__content {
  border-radius: 0;
}
</style>
