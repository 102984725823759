<template>
  <v-main class="pb-10">
    <v-row>
      <v-col cols="12" lg="9" :order="isDesktop ? 'first' : 'last'">
        <services-by-agreement v-for="(com,index) in finances.commercial_agreements" :key="index" :info="com"></services-by-agreement>
      </v-col>
      <v-col cols="12" lg="3" :order="isDesktop ? 'last' : 'first'">
        <payment-details :finances="finances"></payment-details>
      </v-col>
    </v-row>
  </v-main>
</template>

<script>
import { mapActions, mapState } from 'vuex'

import ServicesByAgreement from '../components/Finances/ServicesByAgreement'
import PaymentDetails from '../components/Finances/PaymentDetails'

export default {
  components: {
    ServicesByAgreement,
    PaymentDetails
  },
  computed: {
    ...mapState(['finances']),
    isDesktop(){
      let is_desktop = window.innerWidth > 1264 ? true : false
      return is_desktop
    }
  },
  methods: mapActions(['getFinances']),
  created(){
    this.getFinances()
  }
}
</script>

<style>

</style>
