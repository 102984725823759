<template>
  <div>
    <v-row class="mb-5">
      <v-col cols="4">
        <v-card flat>
          <div class="px-5 py-4 white--text" style="background: #2222BF">
            <v-chip :color="info.status=='Activa'? '#00AF7D' : '#E90019'" text-color="white" x-small class="px-2 mb-1 font-weight-medium">{{info.status}}</v-chip>
            <p class="mb-0 font-weight-bold" style="font-size: 14px">{{info.payingCompany.razonSocial}}</p>
            <p class="mb-0" style="font-size: 12px;">RUT: {{info.payingCompany.rut}}<span class="ml-2">[{{info.payingCompany.empresa.nombre}}]</span></p>
          </div>
          <div class="px-5 pt-4 pb-2">
            <p>
              <v-icon color="#2222BF" class="mr-1">mdi-file-pdf-outline</v-icon>
              <a style="color: #2222bf; font-size: 12px" :href="`${entorno}/es/remuneraciones/avanzados/editar-acuerdo-comercial/${info.id}`" target="_blank">Acuerdo comercial N° {{ info.id }}</a>
            </p>
            <div v-for="(company,i) in info.companies" :key="'company'+i" class="mt-4">
              <p class="mb-0 font-weight-bold" style="font-size: 14px;">{{company.nombre}}</p>
              <div v-for="(rs,i) in company.business_names" :key="'business'+i" class="mt-2">
                <p class="mb-0" style="font-size: 12px; color: #757575">RUT: {{rs.rut}}</p>
                <p class="mb-0" style="font-size: 13px">{{rs.razonSocial}}</p>
              </div>
            </div>
            <div v-for="(razon,i) in info.razonesSociales" :key="'razonSocial'+i" :class="i==0 ? 'mt-6' : 'mt-2'" >
              <p class="mb-0 font-weight-bold" style="font-size: 14px;">{{razon.empresa ? razon.empresa.nombre : '--'}}</p>
              <div class="mt-2">
                <p class="mb-0" style="font-size: 12px; color: #757575">RUT: {{razon.rut}}</p>
                <p class="mb-0" style="font-size: 13px">{{razon.razonSocial}}</p>
              </div>
            </div>
            <!-- <v-divider class="my-4"></v-divider>
            <div style="font-size: 13px">
              <p class="mb-0" style="color: #757575;">Encargado de facturación</p>
              <p class="font-weight-bold mb-0">Nombre</p>
              <p class="mb-0">Cargo</p>
              <p class="mb-0 text-decoration-underline" style="color: #2222BF">email</p>
              <p class="mb-0">Tel: teléfono</p>
            </div> -->
            <v-divider class="my-4"></v-divider>
            <p class="mb-2" style="font-size: 13px; color: #757575"><v-icon color="#FFD000" class="mr-1 mb-1">mdi-power-plug-off-outline</v-icon>{{ info.collection_process }}</p>
          </div>
        </v-card>
      </v-col>
      <v-col cols="8">
        <v-card flat class="px-4 py-3">
          <v-btn text absolute right class="text-none font-weight-regular mx-0 px-0" style="font-size: 13px" color="#2222BF" :href="`${entorno}/es/facturacion/morosos/${info.payingCompany.id}`" target="_blank" >
            Ver más
            <v-icon color="#2222BF" class="ml-1">mdi-arrow-right</v-icon>
          </v-btn>
          <div class="pa-2">
            <p class="font-weight-bold mb-6">Plan contratado</p>
            <p class="font-weight-bold mb-0" v-html="info.hired_plan || 'Sin plan'" style="font-size: 14px"></p>
            <services-icons :services="info.detailAgreement.services"></services-icons>
            <v-divider class="my-4"></v-divider>
            <v-row>
              <v-col cols="3">
                <p class="mb-0" style="font-size: 13px; color: #757575">Tipo de facturación</p>
                <p class="font-weight-medium mb-0">{{info.billingCycle}}</p>
              </v-col>
              <v-col cols="3">
                <p class="mb-0" style="font-size: 13px; color: #757575">N° de empleados</p>
                <p class="mb-0" style="font-size: 13px; color: #757575">proyectados</p>
                <p class="font-weight-medium mb-0">{{info.projected_workers || 0}}</p>
              </v-col>
              <v-col cols="3">
                <p class="mb-0" style="font-size: 13px; color: #757575">Tarifa por tbjr. únicos activos</p>
                <p class="font-weight-medium mb-0">{{activeWorkersValue(info.detailAgreement.services)}}</p>
              </v-col>
              <v-col cols="3">
                <p class="mb-0" style="font-size: 13px; color: #757575">Tarifa por tbjr. con turnos asignados</p>
                <p class="font-weight-medium mb-0">{{shiftWorkersValue(info.detailAgreement.services)}}</p>
              </v-col>
              <v-col cols="3">
                <p class="mb-0" style="font-size: 13px; color: #757575">Costo de implementación</p>
                <p class="font-weight-medium mb-0">UF {{info.valor_implementacion}}</p>
              </v-col>
            </v-row>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import ServicesIcons from '../Finances/ServicesIcons'

let WEB_HOST = window.location.host.includes('localhost') ? 'http://localhost:9000' : 'https://' + window.location.host.substring(8)

export default {
  props: ['info'],
  data: () => ({
    entorno: WEB_HOST
  }),
  components: {
    ServicesIcons
  },
  methods: {
    shiftWorkersValue(services){
      let found = services.find(s => s.name == 'Turnos')
      let value = found ? `UF ${found.employeePrice}` : '-'
      return value
    },
    activeWorkersValue(services){
      let found = services.find(s => s.name != 'Turnos')
      let value = found ? `UF ${found.employeePrice}` : '-'
      return value
    }
  }
}
</script>

<style lang="scss">
  .border-right {
    border-right: 1px solid #0000001f;
  }
</style>
