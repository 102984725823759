<template>
  <div>
    <v-card flat class="pa-4 pb-1 mb-2 blue_line">
      <p class="mb-0" style="font-size: 11px; color: #757575">N° de empleados activos {{getPastMonth}}</p>
      <p class="font-weight-medium" style="font-size: 18px;">{{formattedNumber(finances.active_employees)}}</p>
    </v-card>
    <v-card flat class="pa-4 mb-2 blue_line">
      <p class="font-weight-bold mb-6">Facturación equivalente mensual
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              style="color: #757575; font-size: 18px; vertical-align: baseline;"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>Equivale al total mensual por razón social.</span>
        </v-tooltip>
      </p>
      <div v-for="(debt,i) in finances.debts" :key="i">
        <p class="mb-0" style="font-size: 11px; color: #757575">{{debt.business_name.razonSocial}}</p>
        <p class="font-weight-medium" style="font-size: 18px;">$ {{formattedNumber(debt.monthly_billing)}}</p>
      </div>
    </v-card>
    <v-card flat class="pa-4 blue_line">
      <p class="font-weight-bold mb-6">Deudas
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              style="color: #757575; font-size: 18px; vertical-align: baseline;"
              dark
              v-bind="attrs"
              v-on="on"
            >
              mdi-alert-circle-outline
            </v-icon>
          </template>
          <span>Equivale al total adeudado por razón razon.</span>
        </v-tooltip>
      </p>
      <div v-for="(debt,i) in finances.debts" :key="i">
        <p class="mb-0" style="font-size: 11px; color: #757575">{{debt.business_name.razonSocial}}</p>
        <p class="font-weight-medium" style="font-size: 18px;">$ {{formattedNumber(debt.total_debt)}}</p>
      </div>
      <v-divider class="my-2"></v-divider>
      <p class="mb-0" style="font-size: 13px; color: #757575">Deuda total</p>
      <p class="font-weight-medium mb-3" style="font-size: 20px;">$ {{formattedNumber(finances.total)}}</p>
      <v-btn small outlined class="text-none font-weight-regular px-2" color="#2222BF" :href="`${WEB_HOST}/es/facturacion/morosos/${company.id}`">
        Ver pagos y facturas
      </v-btn>
    </v-card>
  </div>
</template>

<script>
let WEB_HOST = window.location.host.includes('localhost') ? 'http://localhost:9000' : 'https://' + window.location.host.substring(8)

import { mapGetters, mapState } from 'vuex'
export default {
  props: ['finances'],
  data() {
    return {
      WEB_HOST
    }
  },
  computed: {
    ...mapState(['company']),
    ...mapGetters(['formattedNumber']),
    getPastMonth() {
      return this.$moment().subtract(1,'months').endOf('month').format('MMMM');
    }
  }
}
</script>

<style lang="scss">
.blue_line{
  border-left:6px solid #2222BF!important;
}
</style>
