import { render, staticRenderFns } from "./SummaryCommercial.vue?vue&type=template&id=725ca91a&"
import script from "./SummaryCommercial.vue?vue&type=script&lang=js&"
export * from "./SummaryCommercial.vue?vue&type=script&lang=js&"
import style0 from "./SummaryCommercial.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VCard,VDivider,VIcon})
