<template>
  <div>
    <v-card v-if="logs.length" flat class="px-5 py-5">
      <div class="py-2 mb-4" style="position: relative; font-size: 13px;">
        <p class="my-0" style="width: 75%; font-size: 16px; font-weight: bold;">Últimas notas de bitácora</p>
        <router-link :to="'/bitacora'" style="position: absolute; right: 0; top: 50%; transform: translateY(-50%); color: #2222BF; max-width: 50%; text-align: right; font-size: 13px;">Ver todos</router-link>
      </div>
      <div>
        <v-row v-for="(log, i) in logs" :key="i">
          <v-col cols="12" lg="2" class="md-2 pt-4 d-flex">
            <v-avatar size="36" :color="log.author.personadetalle_set.length>0 && log.author.personadetalle_set[0].foto ? '': '#2222BF'">
              <v-img :src="log.author.personadetalle_set[0].foto" v-if="log.author.personadetalle_set.length>0 && log.author.personadetalle_set[0].foto"></v-img>
              <span class="white--text" v-else>{{log.author.nombre[0]}}{{log.author.apellidoPaterno[0]}}</span>
            </v-avatar>
          </v-col>
          <v-col cols="12" lg="10">
            <div
              class="mb-0"
              style="position: relative;"
            >
              <p class="ma-0" style="font-size: 12px; color: #757575; display: inline-block;">Publicado el {{log.created | moment('DD/MM/YYYY, HH:mm')}}</p>
              <p class="ma-0" style="font-size: 13px; color: #181818;">{{getAuthorName(log.author)}}</p>
              <span style="position: absolute; right: 0; top: 5px; color: #2222BF; max-width: 50%; text-align: right; font-size: 11px;">{{log.category ? log.category.name : '-'}}</span>
            </div>
            <p class="my-3" style="font-size: 14px; font-weight: bold;">{{log.title ? log.title : '-'}}</p>
            <p style="font-size: 13px; word-break: break-all;" v-html="log.anotation ? log.anotation : emptyLog"></p>
            <v-divider v-if="(i + 1) < logs.length"></v-divider>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card v-else flat class="px-6" style="text-align: center; padding-top: 250px; padding-bottom: 250px;">
      <v-icon class="my-5" style="font-size: 90px;">mdi-notebook-edit-outline</v-icon>
      <p class="my-1" style="font-weight: bold; font-size: 18px;">Aún no hay notas creadas.</p>
      <p class="my-1" style="color: #747474; font-size: 14px;">Puedes registrar cualquier tipo de cambio que le sea útil al resto del equipo.</p>
      <v-btn @click="openNotePadBtn" small outlined color="#2222BF" class="text-none font-weight-regular letter_spacing px-2 my-5" style="font-size: 14px">
        <v-icon class="mr-1">mdi-plus-circle-outline</v-icon>
        Nueva nota
      </v-btn>
    </v-card>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: ['logs'],
  data: () => ({
    publicPath: location.origin,
    emptyLog: '<p>Sin contenido</p>'
  }),
  methods: {
    ...mapActions(['openNotePad']),
    getAuthorName(author) {
      if (!author) return '-'
      if (!author.nombre && !author.apellidoPaterno && !author.apellidoMaterno) return '-'
      return `${author.nombre} ${author.apellidoPaterno} ${author.apellidoMaterno}`
    },
    openNotePadBtn() {
      this.openNotePad()
      this.$router.replace('/bitacora')
    }
  }
}
</script>

<style lang="scss">

</style>
