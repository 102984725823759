<template>
  <div>
    <p style="font-size: 13px; color: #757575">Servicios contratados y tarifa mínima</p>
    <v-row dense>
      <v-col cols="2" class="text-center mx-md-2 d-inline-block" v-for="(service,i) in services" :key="i">
        <v-img
          contain
          height="25px"
          class="mx-auto"
          :src="serviceIcon(service.name)"
        ></v-img>
        <div
          style="height: 32px"
          class="mb-0 d-flex align-content-space-around flex-wrap justify-center"
        >
          <p
            class="mb-0 font-weight-medium"
            style="color: #2222bf; font-size: 12px; line-height: 1.2"
          >
            {{serviceName(service.name)}}
          </p>
        </div>
        <p style="color: #757575; font-size: 13px" class="mb-0">UF {{service.minimunPrice}}</p>
      </v-col>
    </v-row>
  </div>
</template>

<script>
export default {
  props: ['services'],
  data: () => ({
    publicPath: location.origin
  }),
  methods: {
    serviceIcon(name){
      let icon = ''
      if(name=="Turnos"){ icon = 'asistencia_turnos.png'}
      if(name=="Comunicaciones"){ icon = 'comunicaciones.png'}
      if(name=="Gestión de Personas"){ icon = 'gestion_personas.png'}
      if(name=="Firma Digital"){icon = 'firma_digital.png'}
      if(name=="Remuneraciones"){icon = 'remuneraciones.png'}
      if(name=="DO"){icon = 'desarrollo_organizacional.png'}
      if(name=="Mesa de Ayuda"){icon = 'centro_solicitudes.png'}
      return `${this.publicPath}/img/services_icons/${icon}`
    },
    serviceName(name){
      if(name=="Turnos"){return "Control de Asistencia y Turnos"}
      if(name=="DO"){return "Desarrollo Organizacional"}
      return name
    }
  }
}
</script>
